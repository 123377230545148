import React, { FC } from 'react';
import twitter_packym from '../../content/img/learning-hub/voices-we-respect/twitter_packym.png';
import twitter_prestonpysh from '../../content/img/learning-hub/voices-we-respect/twitter_prestonpysh.png';
import twitter_balajis from '../../content/img/learning-hub/voices-we-respect/twitter_balajis.png';
import twitter_cryptohayes from '../../content/img/learning-hub/voices-we-respect/twitter_cryptohayes.png';
import twitter_taschalabs from '../../content/img/learning-hub/voices-we-respect/twitter_taschalabs.png';
import twitter_apompliano from '../../content/img/learning-hub/voices-we-respect/twitter_apompliano.png';
import twitter_niccarter from '../../content/img/learning-hub/voices-we-respect/twitter_niccarter.png';
import twitter_twobitidiot from '../../content/img/learning-hub/voices-we-respect/twitter_twobitidiot.png';
import twitter_jackniewold from '../../content/img/learning-hub/voices-we-respect/twitter_jackniewold.png';
import twitter_thedefiedge from '../../content/img/learning-hub/voices-we-respect/twitter_thedefiedge.png';
import twitter_milesdeutscher from '../../content/img/learning-hub/voices-we-respect/twitter_milesdeutscher.png';
import { AppIcon } from 'common';
import { MainContent } from '../layout/main-content';
import HeadingText from '../HeadingText';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface DataProps {
  name: string;
  avatar: string;
  twitter_url: string;
  twitter_handle: string;
}

const data: DataProps[] = [
  {
    name: 'Packy McCormick',
    avatar: twitter_packym,
    twitter_url: 'https://twitter.com/packyM',
    twitter_handle: '@packyM',
  },
  {
    name: 'Chris Dixon',
    avatar: twitter_prestonpysh,
    twitter_url: 'https://twitter.com/PrestonPysh',
    twitter_handle: '@Chris Dixon',
  },
  {
    name: 'Balaji Srinivasan',
    avatar: twitter_balajis,
    twitter_url: 'https://twitter.com/balajis',
    twitter_handle: '@balajis',
  },
  {
    name: 'Arthur Hayes',
    avatar: twitter_cryptohayes,
    twitter_url: 'https://twitter.com/CryptoHayes',
    twitter_handle: '@CryptoHayes',
  },
  {
    name: 'Tascha Che',
    avatar: twitter_taschalabs,
    twitter_url: 'https://twitter.com/TaschaLabs',
    twitter_handle: '@TaschaLabs',
  },
  {
    name: 'Antony Pompliano',
    avatar: twitter_apompliano,
    twitter_url: 'https://twitter.com/APompliano',
    twitter_handle: '@APompliano',
  },
  {
    name: 'Nic Carter',
    avatar: twitter_niccarter,
    twitter_url: 'https://twitter.com/nic__carter',
    twitter_handle: '@nic__carter',
  },
  {
    name: 'Ryan Selkis',
    avatar: twitter_twobitidiot,
    twitter_url: 'https://twitter.com/twobitidiot',
    twitter_handle: '@twobitidiot',
  },
  {
    name: 'Jack Nieworld',
    avatar: twitter_jackniewold,
    twitter_url: 'https://twitter.com/JackNiewold',
    twitter_handle: '@JackNiewold',
  },
  {
    name: 'The Defi Edge',
    avatar: twitter_thedefiedge,
    twitter_url: 'https://twitter.com/theDefiEdge',
    twitter_handle: '@theDefiEdge',
  },
  {
    name: 'Miles Deutscher',
    avatar: twitter_milesdeutscher,
    twitter_url: 'https://twitter.com/milesdeutscher',
    twitter_handle: '@milesdeutscher',
  },
];

const Card: FC<DataProps> = ({ avatar, name, twitter_handle, twitter_url }) => {
  return (
    <div
      className="snap-center w-40 h-56 mx-2"
      style={{
        flexShrink: 0,
      }}
    >
      <a
        href={twitter_url}
        target="_blank"
        className="flex rounded flex-col md:flex-nowrap person order-1 items-center  w-full h-full justify-center bg-white hover-bg-accent cursor-pointer"
      >
        <img
          className="rounded-full"
          src={avatar}
          style={{
            width: 70,
            height: 70,
          }}
        />
        <div className="text-sh-b2 mt-4 text-center px-2">
          <p className="font-semibold">{name}</p>
          <p className="text-sh-gray-dark">{twitter_handle}</p>
        </div>
      </a>
    </div>
  );
};

export const VoicesWeRespect = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm;

  return (
    <section className="flex flex-col items-center">
      <MainContent
        width="max-w-7xl"
        cls="grid grid-cols-5 md:grid-cols-14 gap-x-3 p-16 md:my-0 justify-center items-center stories-we-like"
      >
        <div className="col-start-1 col-span-full place-self-center">
          <AppIcon icon="negative-twitter" size={isMobile ? 32 : 60} />
        </div>
        <div className="col-start-1 col-span-full flex place-items-center place-content-center mt-4 mb-9">
          <HeadingText
            headingLevel={3}
            inputText={'Voices we respect'}
            className="leading-6 text-2xl font-semibold px-4 lg:px-8"
          />
        </div>
        <div className="flex flex-row snap-x snap-mandatory w-screen overflow-x-scroll max-w-6xl pb-6 pr-12">
          {data.map(item => (
            <Card {...item} key={item.twitter_handle} />
          ))}
        </div>
      </MainContent>
    </section>
  );
};
