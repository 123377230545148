import { AppIcon, AppIconType, DATE_FORMATS } from 'common';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { MainContent } from '../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HeadingText from '../HeadingText';

interface InsightsNewsDataProps {
  icon: Partial<AppIconType>;
  date: number | Date;
  header: string;
  content: string;
  weeklyCount: string;
}

const insightsNewsData: InsightsNewsDataProps[] = [
  {
    icon: `insights-pulse`,
    date: new Date(),
    header: `The Weekly Stable`,
    content: `We’ve merged, now what?`,
    weeklyCount: `Week 2`,
  },
  {
    icon: `insights-update`,
    date: new Date(),
    header: `The Crypto Pulse`,
    content: `Merging into ugliness`,
    weeklyCount: `Week 2`,
  },
];
export const InsightsNews: FC = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm;
  return (
    <MainContent
      cls="mt-14 flex md:flex-row gap-12 flex-col justify-center"
      width="max-w-7xl"
    >
      {insightsNewsData?.length > 0 &&
        insightsNewsData?.map(({ icon, date, header, content, weeklyCount }) => (
          <div
            key={header}
            className="col-start-1 col-span-full md:col-start-2 md:col-span-12 md:flex md:flex-row md:flex-wrap gap-x-4 lg:gap-x-8 story-wrapper mx-auto w-full"
          >
            <div className="w-full bg-accent-fair hover:bg-white p-6 rounded-lg">
              <div className="flex flex-col lg:flex-row md:flex-row flex-wrap">
                <AppIcon icon={icon} size={isMobile ? 32 : 60} cls="mr-6 mb-2.5" />

                <div>
                  <HeadingText
                    headingLevel={3}
                    inputText={header}
                    className="font-semibold typo-h4 md:typo-h3"
                  />

                  <div className="mt-2 mb-4 ">
                    <p className="text-base md:text-xl">{content}</p>
                  </div>
                  <div className="flex flex-row items-center text-sm xs:text-base">
                    <AppIcon icon="twitter-gray" size={20} cls="mr-2 items-center" />
                    <div className="divide-x divide-gray-400 text-gray-400">
                      <span className="pr-2">{weeklyCount}</span>
                      <span className="pl-2">{format(date, DATE_FORMATS.PPP)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </MainContent>
  );
};
