import React, { FC } from 'react';

interface Props {
  tags: string[];
}

export const BlogTags: FC<Props> = ({ tags }) => {
  if (!tags) {
    return null;
  }
  return (
    <>
      {tags.map(t => {
        return (
          <span
            className="rounded-4 py-1 px-2 mr-2 text-xs bg-accent-light font-semibold"
            style={{
              textDecoration: 'none',
            }}
            key={t}
          >
            {t}
          </span>
        );
      })}
    </>
  );
};
