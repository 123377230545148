import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BlogTags } from './blog-tags';
import { getTags } from '../../utils/get-tags';
import { AppIcon } from 'common';
import { MainContent } from '../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HeadingText from '../HeadingText';

export const Blog: FC<any> = ({ data }: any) => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  if (!data) {
    return null;
  }
  const isMobile = breakpoints.xs || breakpoints.sm;
  return (
    <MainContent
      cls="grid grid-cols-5 md:grid-cols-12 gap-x-3 lg:gap-x-6 px-8 md:my-0 justify-center"
      width="max-w-7xl"
    >
      <div className="col-start-1 col-span-full flex place-items-center place-content-center mt-16">
        <AppIcon icon="negative-pencil" size={isMobile ? 32 : 60} />
      </div>
      <div className="col-start-1 col-span-full flex place-items-center place-content-center">
        <HeadingText
          headingLevel={3}
          inputText={`Our latest articles`}
          className="leading-6 text-2xl font-semibold px-4 lg:px-8 mt-4 mb-2"
        />
      </div>
      <div className="flex flex-col items-center align-middle"></div>
      <div className="col-span-full md:flex lg:flex-row flex-wrap gap-x-5 xl:gap-x-8 mt-6 article-wrapper">
        {data.map(({ node }, index) => {
          const { frontmatter } = node;
          const blogImage = getImage(
            frontmatter.mobileImage?.childImageSharp?.gatsbyImageData
          );
          const tags = getTags(frontmatter.category, frontmatter.type);
          return (
            <a
              key={frontmatter.title}
              href={frontmatter.slug}
              className={`bg-white rounded-20 no-underline col-span-5 md:col-span-4 mb-8 flex flex-col w-full lg:w-auto article-card article-card-${index} `}
            >
              <div className="flex flex-col flex-grow justify-between gap-y-4 lg:gap-y-8">
                <div className="grid gap-y-5">
                  <div className="rounded-t-20 rounded-b-none w-full items-stretch border-b grid blog-img-container">
                    <GatsbyImage
                      image={blogImage}
                      alt="some image"
                      objectFit="contain"
                      className="rounded-t-20 col-start-1 row-start-1 col-span-full row-span-full"
                      imgStyle={{
                        borderTopLeftRadius: '1.25em',
                        borderTopRightRadius: '1.25em',
                      }}
                    />
                    <p className="font-light typo-b2 lg:text-sh-h4 rounded-t-20 col-start-1 row-start-1 col-span-full row-span-full scrim grid place-content-center">
                      Read full article
                    </p>
                  </div>
                  <HeadingText
                    headingLevel={3}
                    inputText={frontmatter?.title}
                    id={frontmatter?.id}
                    className="leading-6 typo-b1 font-semibold px-4 lg:px-8"
                  />
                </div>

                <div className="p-4 lg:p-8 gap-y-2 flex flex-col content-end rounded-b-20">
                  <div className="flex flex-wrap gap-y-2">
                    <BlogTags tags={tags} />
                  </div>
                  <p className="my-2 text-grey-darker divide-x divide-gray-400">
                    <span className="text-xs pr-2">Published</span>

                    {frontmatter.date && (
                      <span className="text-xs pl-2">{frontmatter.date}</span>
                    )}
                  </p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </MainContent>
  );
};
