// A basic Image component
import React, { FC } from 'react';

//TS
interface Props {
  source: string | undefined;
  altText: string | undefined;
  className?: string;
  height?: number;
  width?: number;
}

const Image: FC<Props> = ({
  altText,
  height,
  source,
  className = 'col-span-full md:col-start-4 row-start-1 md:col-span-6',
  width,
}) => {
  return (
    <img className={className} src={source} alt={altText} height={height} width={width} />
  );
};

export default Image;
