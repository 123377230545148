import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Blog } from '../../components/learning-hub/blog';
import { PageLayout } from '../../components/layout';
import {
  LearningHubHero,
  VoicesWeRespect,
  EssentialInsights,
} from '../../components/learning-hub';
import Fade from 'react-reveal/Fade';

import './index.scss';
import '../../styles/article-card.scss';

const Page = () => {
  return (
    <StaticQuery
      query={graphql`
        query getMdData {
          allMarkdownRemark(
            sort: { fields: [frontmatter___order], order: DESC }
            filter: { frontmatter: { hideArticle: { ne: true } } }
          ) {
            edges {
              node {
                html
                frontmatter {
                  order
                  id
                  title
                  mobileImage {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                        aspectRatio: 1.7
                      )
                    }
                  }
                  slug
                  category
                  type
                  date
                  readingTime
                  hideArticle
                }
              }
            }
          }
        }
      `}
      render={data => {
        /*
         * DOM
         */
        const { allMarkdownRemark } = data || {};
        if (!allMarkdownRemark) {
          return null;
        }
        return (
          <PageLayout bgCls="bg-accent-light">
            <Fade>
              <LearningHubHero />
            </Fade>
            <Blog data={allMarkdownRemark.edges || []} />
            <EssentialInsights />
            <VoicesWeRespect />
          </PageLayout>
        );
      }}
    />
  );
};

export default Page;
