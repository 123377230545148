export const getTags = (category: string, type = '') => {

  const result = []
  if (category && category.trim().length) {
    result.push(...category.split(',').map(c => c.trim()))
  }
  if (type && type.trim().length) {
    result.push(...type.split(',').map(t => t.trim()))
  }
  return result;
};
