import React, { FC } from 'react';
import HeadingText from '../HeadingText';
import Image from '../Image';
import insight_1 from '../../content/img/learning-hub/insights/insight_1.png';
import insight_2 from '../../content/img/learning-hub/insights/insight_2.png';
import insight_3 from '../../content/img/learning-hub/insights/insight_3.png';
import insight_4 from '../../content/img/learning-hub/insights/insight_4.png';
import insight_5 from '../../content/img/learning-hub/insights/insight_5.png';
import insight_6 from '../../content/img/learning-hub/insights/insight_6.png';
import { AppIcon } from 'common';
import { MainContent } from '../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface DataProps {
  title: string;
  avatar: string;
  url: string;
  readingTime: number;
  date?: string;
}

const data: DataProps[] = [
  {
    title: `Bitcoin: Peer-to-peer electronic cash system`,
    avatar: insight_1,
    url: `https://vijayboyapati.medium.com/the-bullish-case-for-bitcoin-6ecc8bdecc1`,
    readingTime: 40,
    date: `2 Mar 2018`,
  },
  {
    title: `Why Decentralization matters?`,
    avatar: insight_2,
    url: `https://cdixon.org/2018/02/18/why-decentralization-matters `,
    readingTime: 5,
    date: `2 Mar 2018`,
  },
  {
    title: `Why Bitcoin Matters for Freedom`,
    avatar: insight_3,
    url: `https://time.com/5486673/bitcoin-venezuela-authoritarian/`,
    readingTime: 10,
    date: `28 Dec 2018`,
  },
  {
    title: `Bitcoin for the open minded skeptic`,
    avatar: insight_4,
    url: `https://www.paradigm.xyz/2020/05/bitcoin-for-the-open-minded-skeptic`,
    readingTime: 10,
    date: `11 May 2020`
  },
  {
    title: `Digital Dollars `,
    avatar: insight_5,
    url: `https://www.galaxy.com/research/whitepapers/digital-dollars-in-depth-stablecoins/`,
    readingTime: 45,
    date: `15 Aug 2022`
  },
  {
    title: `Devcon2: Ethereum in 25 Minutes`,
    avatar: insight_6,
    url: `https://www.youtube.com/watch?v=66SaEDzlmP4`,
    readingTime: 5,
    date: `11 Oct 2016`,
  },
];

export const EssentialInsights: FC = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm;

  return (
    <div>
      <MainContent cls="grid grid-cols-5 md:grid-cols-14 gap-x-3 pt-16 pb-0 md:my-0 justify-center items-center stories-we-like">
        <div className="col-start-1 col-span-full flex place-content-center place-items-center">
          <AppIcon size={isMobile ? 32 : 60} icon="negative-people-voices" />
        </div>
        <div className="col-start-1 col-span-full flex place-items-center place-content-center my-2">
          <HeadingText
            headingLevel={3}
            inputText={'Expert insights'}
            className="leading-6 text-2xl font-semibold px-4 lg:px-8 mt-4 mb-9"
          />
        </div>
        <div className="col-start-1 col-span-full md:col-start-2 md:col-span-12 md:flex md:flex-row md:flex-wrap gap-x-4 lg:gap-x-8 story-wrapper mx-auto container">
          {data.map(({ title, avatar, readingTime, date, url }, index) => {
            return (
              <a
                key={title}
                href={url}
                target={'_blank'}
                className={`bg-white rounded-20 no-underline col-span-5 md:col-span-4 mb-8 story-card article-card-${index} flex flex-col`}
              >
                <div className="flex flex-col  flex-grow justify-around gap-y-8">
                  <div className="rounded-t-20 rounded-b-none w-full items-stretch border-b grid story-img-container">
                    <Image
                      source={avatar}
                      altText={`Banner image for story ${title}`}
                      className="rounded-t-20 col-start-1 row-start-1 col-span-full row-span-full "
                    />
                    <p className="font-light typo-b2 lg:text-sh-h4 rounded-t-20 col-start-1 row-start-1 col-span-full row-span-full scrim grid place-content-center">
                      Read full article
                    </p>
                  </div>

                  <HeadingText
                    headingLevel={3}
                    inputText={title}
                    id={`learning-hub-insights-${index}`}
                    className="leading-6 typo-b1 font-semibold px-4 lg:px-8"
                  />
                  <div className="py-8 pl-4 md:pl-8">
                    <p className="mt-2 text-grey-darker">
                      <span className="text-xs">{readingTime} min read</span>
                      {date && <span className="text-xs">&nbsp;|&nbsp;{date}</span>}
                    </p>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </MainContent>
    </div>
  );
};
