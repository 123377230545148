import React, { FC } from 'react';
import { MainContent } from '../layout/main-content';
import { SectionTextContent } from '../shared';

export const LearningHubHero: FC = () => {
  /**
   * DOM
   */
  return (
    <MainContent cls="mt-10">
      <div className="flex flex-col items-center align-middle">
        <SectionTextContent
          cls="items-center w-full md:w-7/12"
          title={`Essential insights`}
          titleCls={'ff-secondary text-center text-primary whitespace-pre-line'}
          description={`Get clued up on the latest market trends, news, tips and Stablehouse updates below.`}
          descriptionCls={'text-grey-darker my-6 text-center md:text-xl text-base'}
        />
      </div>
    </MainContent>
  );
};
